import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import styles from "./o-nas.module.css";
import Container from "../components/container";
import Columns from "../components/columns";
import Lightbox from "../components/lightBox";
import Hero from "../components/HeroBanner";

export const query = graphql`
  query {
    heroBg: file(relativePath: { eq: "system-dla-agencji-reklamowej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoquesty: file(relativePath: { eq: "questy-logo-new.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoaura: file(relativePath: { eq: "logo-aura.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const ONas = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Questy – Producent systemów IT"
        description="Producent systemu dla agencji reklamowej i marketingowej ✅ Questy - doświadczona firma z Poznania • Producent i dostawca systemów wspierających biznes"
      />
      <Hero heroImage={data.heroBg} justifyContent="center">
        <h1>Questy</h1>
        <p>Producent rozwiązań IT dla biznesu</p>
      </Hero>
      <Container>
        <Columns>
          <div className="text-answers">
            <div className="text-block">
              <h3
                style={{
                  paddingTop: 20,
                  textAlign: "center",
                }}
              >
                Kim jesteśmy?
              </h3>
              <p> Jesteśmy firmą <strong><a href="https://questy.pl">Questy </a></strong>– doświadczonym producentem i dostawcą 
            oprogramowania dla biznesu, w tym systemu Empireo. Od 2002 roku działamy na polskim rynku B2B, 
            wspierając klientów biznesowych rozwiązaniami informatycznymi, które<strong> pomagają realizować założone 
            strategie i osiągać ambitne cele</strong>. Naszymi klientami są&nbsp;zarówno duże, rozpoznawalne firmy, jak i 
            mniejsze, ale dynamicznie rozwijające się przedsiębiorstwa.</p>
            <p>Naszą misją jest<strong> dostarczanie rozwiązań informatycznych, które wspierają rozwój 
            biznesu</strong>. Chcemy przekazywać naszym klientom dopasowane i elastyczne narzędzia, które 
            sprawią, że będą robić to, co robią, z&nbsp;jeszcze lepszymi efektami. W&nbsp;dłuższym okresie<strong> dążymy do 
            zostania najlepszym dostawcą rozwiązań informatycznych </strong>wykorzystywanych w&nbsp;organizacjach 
            na całym świecie.</p>
            </div>
            <div className="text-answers">
              <Lightbox
                style={{
                  maxWidth: 300,
                  margin: "0 auto",
                  marginTop: "105px",
                }}
                images={[data.logoquesty]}
                alts={[
                  "Questy – producent systemu dla agencji reklamowej i reklamowej",
                ]}
              />
            </div>
          </div>
        </Columns>
      </Container>
      <Container>
        <Columns>
          <div className="text-answers">
            <div className="text-block">
              <h3
                style={{
                  textAlign: "center",
                }}
              >
                Jak działamy?
              </h3>
              <p>
                Projekty IT realizowane są w firmie Questy zgodnie z&nbsp;najlepszymi
                dostępnymi metodykami: <strong>PRINCE2 i&nbsp;SCRUM</strong>.
                Wsparcie systemów jest natomiast prowadzone w oparciu o{" "}
                <strong>praktyki ITIL</strong>. Pracownicy firmy Questy są
                również cenionymi analitykami wymagań, co potwierdza{" "}
                <strong>certyfikat REQB</strong>. Dołącz do&nbsp;licznego grona
                zadowolonych klientów!
              </p>
              <p>Stawiamy na:</p>
              <p>
                <ul>
                  <li>
                    <strong>łatwą komunikację</strong> – przykładamy dużą wagę
                    do&nbsp;zrozumienia potrzeb klientów,
                  </li>
                  <li>
                    <strong>konkretne zasady</strong> – wypracowaliśmy standardy
                    współpracy, które dają przewidywalne rezultaty i&nbsp;sprawdzają
                    się,
                  </li>
                  <li>
                    <strong>myślenie biznesowe</strong> – dostarczamy narzędzia,
                    które generują zysk, oszczędności lub poprawę jakości
                    funkcjonowania przedsiębiorstwa,
                  </li>
                  <li>
                    <strong>bezpieczeństwo</strong>– dbamy na najwyższym
                    poziomie o informacje, którymi dzieli się z nami klient,
                  </li>
                  <li>
                    <strong>elastyczność</strong> – każdy biznes jest inny,
                    dlatego dbamy o elastyczność naszych rozwiązań.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </Columns>
      </Container>
      <Container>
        <Columns>
          <div className="text-answers">
            <div className="text-block">
              <h3
                style={{
                  textAlign: "center",
                }}
              >
                Szukasz większego rozwiązania?
              </h3>

              <p>
                {" "}
                Poznaj system{" "}
                <strong>
                  <a href="https://aurabusiness.pl">Aura Business</a>
                </strong>
                . Przeznaczony jest on dla organizacji, które oczekują
                kompleksowych rozwiązań, usprawniających procesy biznesowe. Są
                to elastyczne i komplementarne dla ERP narzędzia wsparcia
                sprzedaży, marketingu oraz zarządzania usługami. Uwzględniamy
                indywidualne wymagania, dopasowując technologię do wizji rozwoju
                przedsiębiorstwa.
              </p>
              <p>
                <strong>
                  <a href="https://aurabusiness.pl/klienci/referencje">
                    Referencje
                  </a>
                </strong>{" "}
                – sprawdź, co mówią o nas klienci.
                <br />
                <strong>
                  <a href="https://aurabusiness.pl/case-studies">Case study</a>
                </strong>{" "}
                – przeczytaj, jak przebiegały wdrożenia systemu Aura Business.
              </p>
            </div>
            <div className="text-answers">
              <Lightbox
                style={{
                  maxWidth: 330,
                  margin: "0 auto",
                  marginTop: "55px",
                }}
                images={[data.logoaura]}
                alts={[
                  "Aura Business- dedykowane rozwiązania informatyczne dla biznesu",
                ]}
              />
            </div>
          </div>
        </Columns>
      </Container>
    </Layout>
  );
};

export default ONas;
